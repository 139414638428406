<template>
    <div>
        <el-form :model="addForm" :rules="formRules" ref="addForm" label-width="190px" class="demo-ruleForm" :hide-required-asterisk="true">
            <el-form-item label="创建名称" prop="name">
                <el-input v-model.trim="addForm.name" placeholder="2-18个字" maxlength="18" show-word-limit style="width: 420px"></el-input>
            </el-form-item>
            <el-form-item label="客户信息名称" prop="customer_info_group_id">
                <el-select filterable v-model="addForm.customer_info_group_id" placeholder="请选择客户信息名称" style="width: 420px">
                    <el-option v-for="(item, index) in customerList" :key="`customerList_${index}`" :label="item.name" :value="item.customer_info_group_id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="商品类目" prop="good_category_id">
                <el-cascader filterable v-model="addForm.good_category_id" :props="keyObj" style="width: 420px"
                             :options="goodClassList" clearable placeholder="请选择商品类目"></el-cascader>
            </el-form-item>
        </el-form>
        <div style="text-align: center; margin: 40px 0">
            <el-button @click="goBack">上一步</el-button>
            <el-button type="primary" @click="submitForm('addForm')">下一步</el-button>
        </div>
    </div>
</template>

<script>
    import { customerInfoGetModuleList, adminGoodGetCategory, dataCentorTplAddServiceData, dataCentorTplGetServiceData } from '@/utils/apis.js'
    export default {
        data() {
            return {
                addForm: {
                    data_center_tpl_id: Number(this.$route.query.data_center_tpl_id) || null,
                    name: '',
                    customer_info_group_id: null,
                    good_category_id: null,
                },
                formRules: {
                    name: [
                        {required: true, message: '请输入数据中心模板名称', trigger: 'blur'},
                        {min: 2, max: 18, message: '长度在 2 到 18 个字', trigger: 'blur'}
                    ],
                    customer_info_group_id: [
                        {required: true, message: '请选择客户信息名称', trigger: 'change'},
                    ],
                    good_category_id: [
                        {required: true, message: '请选择商品类目', trigger: 'change'},
                    ],
                },
                keyObj: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                customerList: [],
                goodClassList: [],
            }
        },
        mounted() {
            this.getCustomerList()
            this.getGoodClassList()
            // if (this.$route.query.status === 'edit') {
            //     this.getInfo()
            // }
            if (this.$route.query.data_center_tpl_id) {
                this.getInfo()
            }
        },
        methods: {
            getInfo() {
                let params = {
                    data_center_tpl_id: this.$route.query.data_center_tpl_id
                }
                dataCentorTplGetServiceData(params).then((res) => {
                    this.addForm = {
                        data_center_tpl_id: res.data.data_center_tpl_id,
                        name: res.data.data_center_tpl_name,
                        customer_info_group_id: res.data.customer_info_group_id,
                        good_category_id: res.data.good_category_data,
                    }
                })
            },
            getCustomerList() {
                customerInfoGetModuleList({paging: 0}).then((res) => {
                    this.customerList = res.data.list
                })
            },
            getGoodClassList() {
                adminGoodGetCategory().then((res) => {
                    this.goodClassList = res.data
                })
            },
            goBack() {
                this.$router.push('/practice/dataCenter')
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('name', this.addForm.name)
                        formData.append('customer_info_group_id', this.addForm.customer_info_group_id)
                        formData.append('good_category_id', this.addForm.good_category_id[this.addForm.good_category_id.length - 1])
                        if (this.addForm.data_center_tpl_id) {
                            formData.append('data_center_tpl_id', this.addForm.data_center_tpl_id)
                        }
                        dataCentorTplAddServiceData(formData).then((res) => {
                            this.$message.success(res.msg)
                            this.$router.push({
                                path: '/practice/dataStepTwo',
                                query: {
                                    stepActive: 2,
                                    goodClassId: this.addForm.good_category_id[this.addForm.good_category_id.length - 1],
                                    data_center_tpl_id: Number(res.data.data_center_tpl_id),
                                    // status: this.$route.query.status
                                }
                            })
                        }).catch((err) => {})
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .demo-ruleForm {
        margin: 20px 0 0 0;
        height: calc(100vh - 416px);
        ::v-deep .el-form-item__label {
            font-size: 16px;
            color: #333;
        }
    }
</style>